import { Button, Stack } from '@mui/material';
import { PATH_CONTACT } from 'src/routes/paths';
import { COLOR } from 'src/theme/custom/colors';
import { NavProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data }: NavProps) {
  return (
    <Stack component="div" direction="row" spacing={2} alignItems="center">
      {data.map((link) => (
        <NavList key={link.title} item={link} isOffset={isOffset} />
      ))}

      <Button variant="contained" target="_blank" rel="noopener" href={PATH_CONTACT.whatsApp} className='bg-orange-500 hover:bg-orange-600'>
        Entrar em Contato
      </Button>
    </Stack>
  );
}
