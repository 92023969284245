import { CircularProgress } from "@mui/material";

export default function CustomLoadingScreen() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        backgroundColor: "rgba(255, 255, 255)",
        zIndex: 9999,
      }}
    >
      <CircularProgress />
      <img src="/logo/light.png" alt="Logo Full Code" width={300} />
    </div>
  );
}
