import { Button } from '@mui/material';
import { COLOR } from 'src/theme/custom/colors';
import CopyRight from './copyright';
import Social from './social';

export default function Footer() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='bg-[#222222] relative mt-8'>
      <div className="flex justify-center align-center w-full absolute top-[-25px] left-0">
        <Button
          variant="contained"
          onClick={handleScrollToTop}
          className={`bold rounded-lg normal-case p-[10px 20px] bg-[${COLOR.primary}] hover:bg-orange-500 text-white`}
          sx={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
        >
          Volte ao Início
        </Button>
      </div>

      <Social />

      {/* <ContainerRow /> */}

      <CopyRight />
    </div>
  );
}
