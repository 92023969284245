import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------

interface StyledCoverProps {
  height?: string;
}


// --------------------------------------------------------------

export const StyledCover = styled(Stack)(({ height = '350px' }: StyledCoverProps) => ({
  display: 'flex',
  marginTop: -88,
  flexDirection: 'column',
  width: '100%',
  height,
  justifyContent: 'center',
  alignContent: 'center',
  position: 'relative',
  alignItems: 'center',
  backgroundColor: '#1F1F1F',

  '@media (max-width: 750px)': {
    height: '250px',
    padding: '30px',
  },
}));

export const StyledDefaultTitle = styled(Stack)(() => ({
  width: '300px',
  backgroundColor: 'black',
  borderRadius: '10px',
  margin: '20px',
}));

export const StyleTitleDivider = styled(Stack)(() => ({
  width: '500px',
  backgroundColor: 'white',
  borderRadius: '10px',
  margin: '20px',
}));
