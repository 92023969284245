import { ElementType, Suspense, lazy } from 'react';
import CustomLoadingScreen from 'src/features/components/loadingScreen';

// ------------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<CustomLoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ------------------------------------------------------------------------

export const Dev = Loadable(lazy(() => import('../features/pages/dev/index')));

export const SobreNos = Loadable(lazy(() => import('../features/pages/aboutUsPage/index')));

export const Blog = Loadable(lazy(() => import('../features/pages/blog/index')));

export const DesenvolvimentoSoftware = Loadable(
  lazy(() => import('../features/pages/solucoes/desenvolvimentoPage/index'))
);

export const FrontEnd = Loadable(
  lazy(() => import('../features/pages/desenvolvimento/frontendPage/index'))
);

export const Backend = Loadable(
  lazy(() => import('../features/pages/desenvolvimento/backendPage/index'))
);

export const DevOps = Loadable(lazy(() => import('../features/pages/desenvolvimento/devopsPage')));

export const MarketingDigital = Loadable(
  lazy(() => import('../features/pages/solucoes/marketingPage/index'))
);

export const SistemasEmpresariais = Loadable(
  lazy(() => import('../features/pages/solucoes/sistemasPage/index'))
);

export const Cursos = Loadable(lazy(() => import('../features/pages/solucoes/cursos/index')));

export const CursosAoVivo = Loadable(
  lazy(() => import('../features/pages/cursos/cursoFrontPage/index'))
);

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const HomePage = Loadable(lazy(() => import('../features/pages/homePage')));

export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));

export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

export const AboutUs = Loadable(lazy(() => import('../features/pages/aboutUsPage/index')));

export const ContactUs = Loadable(lazy(() => import('../features/pages/contactUsPage/index')));

export const WorkUs = Loadable(lazy(() => import('../features/pages/workUsPage/index')));
