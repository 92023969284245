import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PATH_PAGE } from 'src/routes/paths';
import { COLOR } from 'src/theme/custom/colors';
import Countdown from '../countdown';
import { StyledCover } from './styles';

// ---------------------------------------------------------------------

interface CoverProps {
  title: string;
  subtitle?: string;
  count?: boolean;
  height?: string;
  subFinished?: boolean;
  redirect?: {
    path?: string;
    variant?: string;
    title?: string;
  }[];
  links: {
    name: string;
    href?: string;
  }[];
}

// ---------------------------------------------------------------------

export default function CoverWithoutImage({
  title,
  subtitle,
  links,
  redirect,
  count,
  height,
  subFinished,
}: CoverProps) {
  return (
    <StyledCover height={height} className=''>
      <Stack display="flex" justifyContent="center" mt={10}>
        <Typography variant="h3" align="center" color="white">
          {title}
        </Typography>
      </Stack>

      <Typography variant="h5" align="center" color={COLOR.orange}>
        {subtitle}
      </Typography>

      <Stack mt={5}>
        <CustomBreadcrumbs links={links} cover />
      </Stack>

      {redirect &&
        redirect.map((data: any) => (
          <Stack textAlign="center">
            <Link to={data.path} title={data.title}>
              <Button variant={data.variant}>{data.title}</Button>
            </Link>
          </Stack>
        ))}

      {count && <Countdown />}

      {subFinished && <SubFinished />}
    </StyledCover>
  );
}

export function SubFinished() {
  return (
    <>
      <h2 style={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>
        Inscrições encerradas
      </h2>

      <p style={{ color: 'white', marginBottom: '20px', marginTop: '10px' }}>
        Entre na fila de espera para turma 2025/2 clicando no botão abaixo e garanta a{' '}
        <span style={{ color: 'orange' }}>mudança da sua vida!</span>
      </p>

      <Link title="fazer inscrição" to={PATH_PAGE.inscricoes} target="_blank">
        <Button
          fullWidth
          variant="contained"
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            textTransform: 'none',
          }}
        >
          QUERO MUDAR MINHA VIDA
        </Button>
      </Link>
    </>
  );
}
